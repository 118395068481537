import * as React from "react"
import { graphql } from "gatsby"
import {
  extractFilterProductAttribute,
  extractFilterVariantAttribute,
} from "../../config/helper/reducers"
import { ProductsPagined } from "../components/specific/TemplateProducts/ProductsPagined"

//HACK : /!\ collectionIdString => pas de cast sur graphql, strapi declare ID en String saleor en ID
export const query = graphql`
  query ProductsByCollectionPaginatedQuery(
    $collectionId: ID
    $collectionIdString: String
    $ids: [ID]
  ) {
    allStrapiCollectionContent(
      filter: { collection: { saleor_id: { eq: $collectionIdString } } }
    ) {
      edges {
        node {
          description {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
          contenuSEO {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
          liens_haut_de_page {
            name
            saleor_id
            slug
          }
          liens_bas_de_page {
            name
            saleor_id
            slug
          }
          faq {
            question {
              data {
                childMarkdownRemark {
                  html
                }
              }
            }
            reponse {
              data {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
          id
          categorie_mere {
            saleor_id
          }
          Marque
          LogoMarque {
            name
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 300
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          LiensAssocies {
            Text
            Link
            Image {
              name
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 650
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          ImageMarque {
            alternativeText
            name
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 650
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          DescriptionMarque {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
    saleor {
      products(first: 60, filter: { ids: $ids }) {
        totalCount
        edges {
          node {
            ...fragProduct
            thumbnail(size: 512){
              url
              alt
            }
          }
        }
      }
      collection(id: $collectionId) {
        slug
        name
        seoTitle
        seoDescription
      }
    }
  }
`
function extractData(data) {
  let result = {}
  try {
    result = data.allStrapiCollectionContent.edges.map(({ node }) => {
      return {
        description: node.description?.data.childMarkdownRemark.html,
        contenuSEO: node.contenuSEO?.data.childMarkdownRemark.html,

        liens_haut_page: node.liens_haut_de_page?.map(({ name, slug }) => ({
          name,
          slug,
        })),
        liens_bas_page: node.liens_bas_de_page?.map(({ name, slug }) => ({
          name,
          slug,
        })),
        faq: node.faq?.map(({ question, reponse }) => ({
          question: question?.data.childMarkdownRemark.html,
          reponse: reponse?.data.childMarkdownRemark.html,
        })),
        Marque: node.Marque || "",
        DescriptionMarque: node.DescriptionMarque || "",
        LogoMarque: node.LogoMarque,
        LiensAssocies: node.LiensAssocies?.map(({ Text, Link, Image }) => ({
          Text,
          Link,
          Image,
        })),
        ImageMarque: node.ImageMarque,
      }
    })[0]
    const { edges, pageInfo, totalCount } = data.saleor.products
    const collection = data.saleor.collection
    const products = edges?.map(({ node }) => node)
    result = { ...result, ...pageInfo, totalCount, products, collection }
  } catch (error) {
    console.warn("some data from CollectionContent are missing", error)
  }

  return result as {
    description: string
    contenuSEO: string
    liens_haut_page: any
    liens_bas_page: any
    faq: { question: string; reponse: string }
    Marque: string
    DescriptionMarque: any
    LogoMarque: any
    LiensAssocies: any
    ImageMarque: any
  }
}

const PaginatedCollection = ({ pageContext, data }) => {
  // calcul de la liste d'id de productType a utiliser pour chercher les filtres
  let attributesValableFromProduct = data.saleor.products.edges.reduce(
    extractFilterProductAttribute,
    {}
  )
  let attributesValableFromVariant = data.saleor.products.edges.reduce(
    extractFilterVariantAttribute,
    {}
  )

  pageContext = {
    ...pageContext,
    ...extractData(data),
    ...{
      filters: {
        ...attributesValableFromProduct,
        ...attributesValableFromVariant,
      },
    },
  }

  return <ProductsPagined pageContext={pageContext} data={data} />
}

export default PaginatedCollection
